import React, { useEffect } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import SidebarFunnel, { SideItemFunnel } from '../../components/Sidebar';
import { useNavigation } from '../../contexts/NavigationContext';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */
const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { adminRights } = useUser();
  const { menu, setMenu } = useNavigation();

  const linkInfo = [
    {
      name: 'reports',
      svg: 'funnel',
      subtitle: 'Administration et création des funnels',
      surname: 'Rapports',
      enabled: adminRights?.ramses?.reports?.read || false
    },
    {
      name: 'properties',
      svg: 'setting',
      subtitle: 'Gestion des propriétés des funnels',
      surname: 'Propriétés',
      enabled: adminRights?.ramses?.properties?.read || false
    }
  ];

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarFunnel>
      {linkInfo.map(link => {
        return (
          <SideItemFunnel
            key={link.name}
            active={link.name === menu.name}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            onClick={() => updateMenu(link)}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarFunnel>
  );
};

export default SidebarContainer;
