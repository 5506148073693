import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Container, Item, Row } from 'components/Layout';

import ModalGestion from './ModalGestion';
import ModalParams from './ModalParams';

const ModalCampaign = props => {
  const { themeColors } = useTheme();
  const { onClose, config, campaign, onValidate, onDelete, datas } = props;
  const [selectedSection, setSelectedSection] = useState('gestion');
  const getActions = () => {};
  const setupPendingTooltip =
    'Merci d’attendre la fin du setup avant de modifier la campagne.';
  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        onClose();
      }}
      maxWidth={selectedSection === 'gestion' ? 'md' : 'lg'}
    >
      <Container>
        <Row spacing={1}>
          <Item
            flex
            style={{ padding: '10px' }}
            onClick={() => setSelectedSection('gestion')}
          >
            <Text
              link
              fontSize="18px"
              fontWeight={700}
              style={{
                borderBottom:
                  selectedSection === 'gestion'
                    ? `3px solid ${themeColors.activation}`
                    : null
              }}
            >
              Gestion
            </Text>
          </Item>
          {!campaign.isCampaignGroup && (
            <Item
              flex
              style={{ padding: '10px' }}
              onClick={() => setSelectedSection('paramétrage')}
            >
              <Text
                link
                fontSize="18px"
                fontWeight={700}
                style={{
                  borderBottom:
                    selectedSection === 'paramétrage'
                      ? `3px solid ${themeColors.activation}`
                      : null
                }}
              >
                Paramétrage
              </Text>
            </Item>
          )}
        </Row>
        {selectedSection === 'paramétrage' && (
          <ModalParams
            campaign={campaign}
            config={config}
            onValidate={onValidate}
            onClose={onClose}
            setupPendingTooltip={setupPendingTooltip}
          />
        )}
        {selectedSection === 'gestion' && (
          <ModalGestion
            campaign={campaign}
            config={config}
            onValidate={onValidate}
            onDelete={onDelete}
            setupPendingTooltip={setupPendingTooltip}
            datas={{
              importModel: datas.importModel,
              configGenerators: datas.configGenerators,
              configConnexion: datas.configConnexion
            }}
          />
        )}
      </Container>
    </Modal>
  );
};

ModalCampaign.defaultProps = {
  onClose: () => null,
  onValidate: () => {},
  onDelete: () => {}
};
ModalCampaign.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.shape().isRequired,
  campaign: PropTypes.shape().isRequired,
  onValidate: PropTypes.func,
  onDelete: PropTypes.func
};

export default ModalCampaign;
