import React, { useState } from 'react';
import { Chip } from '@mui/material';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, CustomSelect, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  errorName: false,
  errorKey: false,
  errorCpmCampaign: false,
  errorCpmEnrichment: false,
  errorCpmCrm: false,
  errorCpmExport: false
};

export const ModalForm = props => {
  const { onValidate, data } = props;
  let initialState = {
    _id: '',
    key: '',
    name: '',
    cpmCampaign: null,
    cpmEnrichment: null,
    cpmCrm: null,
    cpmExport: null,
    criteriaCategories: []
  };
  initialState = { ...initialState, ...data };

  const [
    {
      _id,
      key,
      name,
      cpmCampaign,
      cpmEnrichment,
      cpmCrm,
      cpmExport,
      categoriesList,
      criteriaCategories
    },
    setState
  ] = useState(initialState);

  const [
    {
      errorName,
      errorKey,
      errorCpmCampaign,
      errorCpmEnrichment,
      errorCpmCrm,
      errorCpmExport
    },
    setError
  ] = useState(ErrorState);
  const handleChange = field => {
    return value => {
      setState(prev => {
        return {
          ...prev,
          [field]: value
        };
      });
    };
  };
  const handleValidate = () => {
    let error = false;
    if (!key) {
      setError(prev => {
        return {
          ...prev,
          errorKey: true
        };
      });
      error = true;
    } else {
      setError(prev => {
        return {
          ...prev,
          errorKey: false
        };
      });
    }
    if (!name) {
      setError(prev => {
        return {
          ...prev,
          errorName: true
        };
      });
      error = true;
    } else {
      setError(prev => {
        return {
          ...prev,
          errorName: false
        };
      });
    }
    if (!cpmCampaign) {
      setError(prev => {
        return {
          ...prev,
          errorCpmCampaign: true
        };
      });
      error = true;
    } else {
      setError(prev => {
        return {
          ...prev,
          errorCpmCampaign: false
        };
      });
    }
    if (!cpmEnrichment) {
      setError(prev => {
        return {
          ...prev,
          errorCpmEnrichment: true
        };
      });
      error = true;
    } else {
      setError(prev => {
        return {
          ...prev,
          errorCpmEnrichment: false
        };
      });
    }
    if (!cpmCrm) {
      setError(prev => {
        return {
          ...prev,
          errorCpmCrm: true
        };
      });
      error = true;
    } else {
      setError(prev => {
        return {
          ...prev,
          errorCpmCrm: false
        };
      });
    }
    if (!cpmExport) {
      setError(prev => {
        return {
          ...prev,
          errorCpmExport: true
        };
      });
      error = true;
    } else {
      setError(prev => {
        return {
          ...prev,
          errorCpmExport: false
        };
      });
    }
    if (error) {
      return;
    }
    const pricing = {
      key,
      name,
      cpmCampaign,
      cpmEnrichment,
      cpmCrm,
      cpmExport,
      criteriaCategories
    };
    if (_id) {
      pricing._id = _id;
    }
    onValidate(pricing);
  };

  return (
    <Modal {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorKey ? 'error' : 'inherit'}>
                            Key *
                          </Text>
                          <TextField
                            disabled={_id}
                            small
                            onChange={handleChange('key')}
                            value={key}
                            error={errorKey}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
            <FormSection title="CPM par défauts" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text
                            color={errorCpmEnrichment ? 'error' : 'inherit'}
                          >
                            Enrichissement data *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('cpmEnrichment')}
                            format="numberDec"
                            value={cpmEnrichment}
                            error={errorCpmEnrichment}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorCpmExport ? 'error' : 'inherit'}>
                            Export audience *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('cpmExport')}
                            value={cpmExport}
                            error={errorCpmExport}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorCpmCrm ? 'error' : 'inherit'}>
                            Campagne CRM *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('cpmCrm')}
                            value={cpmCrm}
                            error={errorCpmCrm}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorCpmCampaign ? 'error' : 'inherit'}>
                            Campagne médias *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('cpmCampaign')}
                            value={cpmCampaign}
                            error={errorCpmCampaign}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Catégories de critères" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start" alignItems="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <CustomSelect
                            small
                            placeHolder="Aucune catégorie de critères"
                            options={categoriesList}
                            selected={criteriaCategories}
                            onChange={list => {
                              handleChange('criteriaCategories')(
                                list.map(el => el.key)
                              );
                            }}
                            selectAll
                            hideNone
                            width="382px"
                            searchBar
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={12}>
                    <Row spacing={0}  justify="flex-start">
                      {criteriaCategories.map(el => {
                        const category = categoriesList.find(
                          cat => cat.key === el
                        );
                        return (
                          <Item flex style={{ padding: '1px' }}>
                            <Chip
                              label={category?.label}
                              onDelete={() => {
                                const newCriteriaCategories = criteriaCategories.filter(
                                  cat => cat !== el
                                );
                                handleChange('criteriaCategories')(
                                  newCriteriaCategories
                                );
                              }}
                            />
                          </Item>
                        );
                      })}
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={2}>
          <Item justify="flex-start">
            <Row
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                style={{
                  'text-transform': 'uppercase'
                }}
                variant="contained"
                onClick={handleValidate}
              >
                <Text color="inherit" fontWeight={600} fontSize="14px">
                  {_id
                    ? 'Actualiser la tarification '
                    : 'Créer la tarification'}
                </Text>
              </Button>
            </Row>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalForm;
