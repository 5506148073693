import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { parseCustomLabel } from 'utils/functions';
import { CUSTOMERS } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  MultipleSelect,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorName: false,
  errorPreset: false
};
export const ModalForm = props => {
  const { config, onNext, onClose } = props;
  const {
    users = [],
    funnels = [],
    activationPresets = [],
    insightUserTarget = [],
    activatedAudiences = [],
    ramsesReports = [],
    actualState,
    id,
    importModel,
    connectorsConnexion,
    stripeCustomers,
    canEditCustomer,
    dataProvidersOptions = []
  } = config;
  const initialState = {
    name: '',
    presetId: '',
    crmPresetId: '',
    selectedUsers: [],
    selectedFunnels: [],
    selectedActivationPresets: [],
    selectedInsightTarget: [],
    selectedActivatedAudiences: [],
    selectedRamsesReports: [],
    selectedPlatformAccounts: [],
    mandats: [],
    selectedPlatformPages: [],
    selectedAccountManagers: [],
    useFunnelDataInActivation: false,
    enableCRMCampaign: false,
    selectedCustomer: {},
    customerName: '',
    key: '',
    selectedDataProviders: [],
    selectedExcludeDataProviders: []
  };
  const [
    {
      name,
      presetId,
      crmPresetId,
      selectedUsers,
      selectedFunnels,
      selectedActivationPresets,
      selectedInsightTarget,
      selectedActivatedAudiences,
      selectedRamsesReports,
      selectedPlatformAccounts,
      selectedPlatformPages,
      selectedAccountManagers,
      mandats,
      useFunnelDataInActivation,
      enableCRMCampaign,
      selectedCustomer,
      key,
      selectedDataProviders,
      selectedExcludeDataProviders
    },
    setState
  ] = useState(id ? actualState : initialState);

  const [{ errorName, errorPreset }, setError] = useState(ErrorState);
  const ownPreset = id
    ? activationPresets.find(preset => preset.name === actualState.name)
    : undefined;

  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };

  const toggleErrorPreset = state => {
    setError(prevState => ({ ...prevState, errorPreset: state }));
  };

  const handleChange = field => value => {
    const newValue = typeof value === 'string' ? value.trim() : value;
    if (field === 'name') {
      toggleErrorName(!newValue);
    }
    if (field === 'crmPresetId' && enableCRMCampaign) {
      toggleErrorPreset(!newValue);
    }
    setState(prevState => ({ ...prevState, [field]: newValue }));
  };

  const handleChangeFunnels = value => {
    setState(prevState => ({
      ...prevState,
      selectedFunnels: value.map(e => funnels.find(f => f._id === e._id))
    }));
  };

  const handleChangePlatformAccount = value => {
    setState(prevState => ({
      ...prevState,
      selectedPlatformAccounts: value.map(el => {
        let { connexionId } = el;
        if (typeof el.connexionId === 'string') {
          connexionId = {
            value: el.connexionId,
            label: connectorsConnexion.find(e => e.value === el.connexionId)
              ?.label
          };
        }
        return {
          ...el,
          connexionId
        };
      })
    }));
  };

  const handleChangePlatformPage = value => {
    setState(prevState => ({
      ...prevState,
      selectedPlatformPages: value.map(el => {
        let { connexionId } = el;
        if (typeof el.connexionId === 'string') {
          connexionId = {
            value: el.connexionId,
            label: connectorsConnexion.find(e => e.value === el.connexionId)
              ?.label
          };
        }
        return {
          ...el,
          connexionId
        };
      })
    }));
  };

  const handleChangeDataProviders = (field) => (value) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const onValidate = async () => {
    let error = false;
    const groupPresetId = presetId || ownPreset?._id;
    const datas = {
      name,
      selectedUsers,
      selectedFunnels,
      selectedActivationPresets,
      selectedInsightTarget,
      selectedActivatedAudiences,
      selectedRamsesReports,
      selectedPlatformAccounts,
      selectedPlatformPages,
      selectedAccountManagers,
      mandats,
      presetId: groupPresetId || '',
      crmPresetId: crmPresetId || groupPresetId,
      useFunnelDataInActivation,
      enableCRMCampaign,
      dataProviders: selectedDataProviders,
      excludeDataProviders: selectedExcludeDataProviders
    };
    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (enableCRMCampaign && !crmPresetId) {
      toggleErrorPreset(true);
      error = true;
    }
    const selectedCustomerId = selectedCustomer?.id;
    if (selectedCustomerId) {
      await CUSTOMERS.updateCostumers(selectedCustomerId, { groupId: id });
    }

    if (!error) {
      onNext(datas);
      resetStates();
    }
  };

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      selectedCustomer: stripeCustomers?.find(c => c.groupId === id)
    }));
  }, [id, stripeCustomers]);

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={onValidate}
            >
              {actualState ? 'Actualiser' : 'Créer'} l&lsquo;organisation
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const onAutoCompliteChange = field => data => {
    setState(prevState => ({ ...prevState, [field]: data?._id }));
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom de l&lsquo;organisation *
                          </Text>
                          <TextField
                            small
                            label="Choisisez un nom pour votre organisation"
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <AutocompleteTextField
                            title="Preset activation par défaut"
                            placeholder="Choisisez un preset activation pour votre organisation"
                            options={activationPresets}
                            formatLabel={option => {
                              return `${option.name} (${option._id})`;
                            }}
                            small
                            value={activationPresets.find(
                              el => el._id === presetId
                            )}
                            onChange={onAutoCompliteChange('presetId')}
                          />
                          {/* <Select
                            small
                            options={activationPresets.map(e => ({
                              label: e.name,
                              value: e._id
                            }))}
                            onChange={handleChange('presetId')}
                            value={presetId}
                          /> */}
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <AutocompleteTextField
                            title="Compte client"
                            options={stripeCustomers}
                            formatLabel={option => {
                              return `${option?.info?.name}`;
                            }}
                            disabled={!canEditCustomer}
                            small
                            value={selectedCustomer}
                            onChange={handleChange('selectedCustomer')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Key du magasin / groupe de magasins"
                            disabled
                            value={parseCustomLabel({ key }, ' - ', ['key'])}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Sources de données" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Container>
                            <Item justify="flex-start">
                              <MultipleSelect
                                title="Inclure"
                                placeHolder="Toutes les sources de données"
                                small
                                selected={selectedDataProviders}
                                options={dataProvidersOptions}
                                onChange={handleChangeDataProviders('selectedDataProviders')}
                              />
                            </Item>
                          </Container>
                        </Item>
                      </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Exclure"
                              placeHolder="Aucune source de données"
                              small
                              selected={selectedExcludeDataProviders}
                              options={dataProvidersOptions}
                              onChange={handleChangeDataProviders('selectedExcludeDataProviders')}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Campagne CRM" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Text>Activer campagne CRM</Text>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Switch
                      checked={enableCRMCampaign}
                      onChange={handleChange('enableCRMCampaign')}
                    />
                  </Item>
                </Row>
                {enableCRMCampaign && (
                  <Row spacing={0} justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <AutocompleteTextField
                        title="Sélectionner un preset CRM"
                        placeholder="Choisisez un preset CRM"
                        options={activationPresets}
                        formatLabel={option => {
                          return `${option.name} (${option._id})`;
                        }}
                        small
                        value={activationPresets.find(
                          el => el._id === crmPresetId
                        )}
                        onChange={onAutoCompliteChange('crmPresetId')}
                        error={errorPreset}
                      />
                    </Item>
                  </Row>
                )}
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Utilisateur" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={users.map(e => ({
                        email: e.email,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['email']}
                      values={selectedUsers}
                      onChange={handleChange('selectedUsers')}
                      actionTxt="Ajouter un utilisateur"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Funnels" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={funnels.map(e => ({
                        name: e.name,
                        email: e.email,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedFunnels.map(e => ({
                        name: e.name,
                        email: e.email,
                        _id: e._id
                      }))}
                      onChange={handleChangeFunnels}
                      actionTxt="Ajouter un Funnel"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Paramètres activation" xs={3}>
              <Container>
                {id && (
                  <Row spacing={0} justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <Text>Utiliser la data du funnel</Text>
                    </Item>
                    <Item xs={6} justify="flex-start">
                      <Switch
                        checked={useFunnelDataInActivation}
                        onChange={handleChange('useFunnelDataInActivation')}
                      />
                    </Item>
                  </Row>
                )}
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={activationPresets.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedActivationPresets}
                      onChange={handleChange('selectedActivationPresets')}
                      actionTxt="Ajouter un Paramètre"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Sauvegardes Audiences" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={insightUserTarget.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedInsightTarget}
                      onChange={handleChange('selectedInsightTarget')}
                      actionTxt="Ajouter une sauvegarde"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mes audiences activée" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={activatedAudiences.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedActivatedAudiences}
                      onChange={handleChange('selectedActivatedAudiences')}
                      actionTxt="Ajouter une audience"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mandat de paiement" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={mandats}
                      onChange={handleChange('mandats')}
                      actionTxt="Ajouter un mandat"
                      deleteAction
                      variant="customFields"
                      inputs={['Select', 'DatePicker', 'DatePicker']}
                      isRequireds={[true, false, false]}
                      labels={['Media', 'date de début', 'date de fin']}
                      options={['media', 'startDate', 'endDate']}
                      fieldsFormat={['media']}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mes rapports Ramses" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={ramsesReports}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedRamsesReports}
                      onChange={handleChange('selectedRamsesReports')}
                      actionTxt="Ajouter un rapport"
                      inputs={['Select']}
                      labels={['Report ID']}
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mes comptes publicitaires" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={selectedPlatformAccounts}
                      onChange={handleChangePlatformAccount}
                      actionTxt="Ajouter un compte"
                      deleteAction
                      variant="customFields"
                      inputs={[
                        'TextField',
                        'Select',
                        'Select',
                        'TextField',
                        'Select'
                      ]}
                      labels={[
                        'Nom',
                        'Nom de la plateforme',
                        'Connexion',
                        'External ID',
                        'Statut'
                      ]}
                      options={[
                        'name',
                        'model',
                        'connexionId',
                        'externalId',
                        'status'
                      ]}
                      fieldsFormat={[
                        '',
                        importModel,
                        connectorsConnexion,
                        '',
                        [
                          { label: 'Validé', value: 'validated' },
                          {
                            label: 'En cours de validation',
                            value: 'notValidated'
                          }
                        ]
                      ]}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mes pages" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={selectedPlatformPages}
                      onChange={handleChangePlatformPage}
                      actionTxt="Ajouter une page"
                      deleteAction
                      variant="customFields"
                      inputs={[
                        'TextField',
                        'Select',
                        'Select',
                        'TextField',
                        'Select'
                      ]}
                      labels={[
                        'Nom',
                        'Media',
                        'Connexion',
                        'External ID',
                        'Statut'
                      ]}
                      options={[
                        'name',
                        'media',
                        'connexionId',
                        'externalId',
                        'status'
                      ]}
                      fieldsFormat={[
                        '',
                        'pageActivableMedia',
                        connectorsConnexion,
                        '',
                        [
                          { label: 'Validé', value: 'validated' },
                          {
                            label: 'En cours de validation',
                            value: 'notValidated'
                          }
                        ]
                      ]}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Account Managers" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={users.reduce((acc, curr) => {
                        if (curr.roles.find(el => el.name === 'ADMIN')) {
                          acc.push({
                            email: curr.email,
                            _id: curr._id
                          });
                        }
                        return acc;
                      }, [])}
                      searchOptionsKeys={['email']}
                      values={selectedAccountManagers}
                      onChange={handleChange('selectedAccountManagers')}
                      actionTxt="Ajouter un account manager"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
