import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import moment from 'utils/moment';

import { Text } from 'components/DataDisplay';
import { DatePicker } from 'components/DatePicker';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorName: false,
  errorRegion: false
};
export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onCreate, onClose } = props;
  const { propertiesList = [], propertie, connexions } = config;

  const initialState = {
    name: propertie?.name || '',
    selectedPropertie: propertie?.selectedPropertie || [],
    sellerId: propertie?.sellerId || '',
    region: propertie?.region || '',
    startDate: propertie?.startDate || undefined,
    endDate: propertie?.endDate || undefined,
    connexionId: propertie?.connexionId || null,
    active: propertie?.active || true,
    minimumStock: propertie?.minimumStock || 60
  };
  const [
    {
      name,
      selectedPropertie,
      sellerId,
      region,
      startDate,
      endDate,
      connexionId,
      active,
      minimumStock
    },
    setState
  ] = React.useState(initialState);
  const [{ errorName, errorRegion }, setError] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`error${itemKey}`] = state;
      return errors;
    });
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const handleChangeActive = () => {
    setState(prevState => ({
      ...prevState,
      active: !active
    }));
  };

  const handleChangeProperties = value => {
    setState(prevState => ({
      ...prevState,
      selectedPropertie: value.map(e =>
        propertiesList.find(f => f._id === e._id)
      )
    }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const onValidate = () => {
    let error = false;
    setError(ErrorState);
    const datas = {
      name,
      sellerId,
      region,
      startDate: moment(startDate).format(),
      endDate,
      connexionId,
      active,
      minimumStock,
      selectedPropertie
    };
    if (!name) {
      toggleError('Name', true);
      error = true;
    }
    if (!region) {
      toggleError('Region', true);
      error = true;
    }
    if (!error) {
      onCreate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.ramses}
              size="medium"
              widthSize="medium"
              onClick={onValidate}
            >
              {propertie ? 'Actualiser' : 'Créer'} la propriété
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const regions = [
    {
      label: 'Europe',
      value: 'eu'
    },
    {
      label: 'Amérique du nord',
      value: 'na'
    },
    {
      label: 'Extrême-Orient',
      value: 'fe'
    }
  ];

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom du rapport *
                          </Text>
                          <TextField
                            small
                            label="Choisisez un nom pour votre rapport"
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Amazon Seller Id</Text>
                          <TextField
                            small
                            // label="Choisisez un nom pour votre propriété"
                            placeholder="1234567890"
                            onChange={handleChange('sellerId')}
                            value={sellerId}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorRegion ? 'error' : 'inherit'}>
                            Région *
                          </Text>
                          <Select
                            onChange={handleChange('region')}
                            options={regions}
                            error={errorRegion}
                            value={region}
                            small
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Connexion</Text>
                          <Select
                            onChange={handleChange('connexionId')}
                            options={connexions}
                            value={connexionId}
                            small
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Actif</Text>
                          <Switch
                            checked={active}
                            onChange={handleChangeActive}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Durée du stock en jours</Text>
                          <TextField
                            small
                            onChange={handleChange('minimumStock')}
                            value={minimumStock}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Date de début</Text>
                          <Item justify="flex-start">
                            <DatePicker
                              onClose={({ date }) =>
                                handleChange('startDate')(
                                  date?.format('YYYY-MM-DD') || ''
                                )
                              }
                              initialDate={
                                moment(startDate, 'YYYY-MM-DD').isValid()
                                  ? moment(startDate, 'YYYY-MM-DD')
                                  : undefined
                              }
                            />
                          </Item>
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Date de fin</Text>
                          <Item justify="flex-start">
                            <DatePicker
                              onClose={({ date }) =>
                                handleChange('endDate')(
                                  date?.format('YYYY-MM-DD') || ''
                                )
                              }
                              initialDate={
                                moment(endDate, 'YYYY-MM-DD').isValid()
                                  ? moment(endDate, 'YYYY-MM-DD')
                                  : undefined
                              }
                            />
                          </Item>
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Propriétés" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={propertiesList.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedPropertie.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      onChange={handleChangeProperties}
                      actionTxt="Ajouter une propriété"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
