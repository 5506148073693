import { api } from './api';

const getMedias = async () => {
  try {
    const result = await api.get(`/backoffice-funnel/funnel/mediasdailyrates`);
    const mediasList = result.data.sort((a, b) => {
      const lowerNameA = a.name.toLowerCase();
      const lowerNameB = b.name.toLowerCase();
      if (lowerNameA < lowerNameB) {
        return -1;
      }
      if (lowerNameA > lowerNameB) {
        return 1;
      }
      return 0;
    });

    return mediasList;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async datas => {
  try {
    const result = await api.post(
      `/backoffice-funnel/funnel/mediasdailyrates`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, datas) => {
  try {
    const result = await api.put(
      `/backoffice-funnel/funnel/mediasdailyrates/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteMedia = async id => {
  try {
    const result = await api.delete(
      `/backoffice-funnel/funnel/mediasdailyrates/${id}`
    );
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getMediaLogos = async () => {
  try {
    const { data } = await api.get(`/v2/funnel/backoffice/mediaLogos`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getMediaLogosbyKeys = async keys => {
  try {
    const { data } = await api.get(`/v2/funnel/backoffice/mediaLogos/byKeys`, {
      params: { keys }
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createMediaLogo = async (key, base64Image, base64Icon) => {
  try {
    await api.post(`/v2/funnel/backoffice/mediaLogos`, {
      key,
      base64Image,
      base64Icon
    });
  } catch (err) {
    console.error(err);
  }
};

const updateMediaLogo = async (mediaLogoId, key, base64Image, base64Icon) => {
  try {
    await api.put(`/v2/funnel/backoffice/mediaLogos/${mediaLogoId}`, {
      key,
      base64Image,
      base64Icon
    });
  } catch (err) {
    console.error(err);
  }
};

export default {
  getMedias,
  create,
  update,
  deleteMedia,
  getMediaLogos,
  getMediaLogosbyKeys,
  createMediaLogo,
  updateMediaLogo
};
