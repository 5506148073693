import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import ActivationModule from 'modules/Activation';
import AudienceModule from 'modules/Audience';
import CampaignModule from 'modules/Campaign';
import FunnelModule from 'modules/Funnel';
import GlobalModule from 'modules/Global';
import PMLModule from 'modules/PML';
import RamsesModule from 'modules/Ramses';

import { useUser } from 'contexts/UserContext';

import { localStorage, routes } from 'utils/constants';
import { routes as routesPolicies } from 'utils/policies';
import { IsUpMd as IsUpMdBreakpoint } from 'utils/responsive';

import AuthTokenContainer from 'containers/AuthContainer/AuthTokenContainer';
import LoginContainer from 'containers/AuthContainer/LoginContainer';
import DashboardContainer from 'containers/DashboardContainer';
import EasterEgg from 'containers/EasterEgg';
import FooterContainer from 'containers/FooterContainer';
import HeaderContainer from 'containers/HeaderContainer';

import { Item } from 'components/Layout';
import LayoutApp from 'components/LayoutApp';

const { AuthenticatedRoute } = routesPolicies;

function App() {
  const IsUpMd = IsUpMdBreakpoint();
  const { isAuthenticated } = useUser();
  // There is marginTop because the HeaderContainer position is 'fixed'
  const styleHeaderFix = isAuthenticated
    ? { marginTop: IsUpMd ? '100px' : '245px' }
    : { marginTop: '0px' };
  // There is marginTop because the HeaderContainer position is 'fixed'
  return (
    <LayoutApp>
      <Router>
        <AuthTokenContainer
          publicRoutes={[routes.LOGIN, routes.DEFAULT]}
          redirectOnError={routes.DEFAULT}
        />
        {/* {isAuthenticated && (
          <Snowfall
            // Applied to the canvas element
            style={{
              background: 'transparent',
              zIndex: 1200,
              position: 'fixed',
              width: '100%',
              height: '100%'
            }}
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={150}
          />
        )} */}
        <HeaderContainer hideOnLocation={[routes.LOGIN, routes.LOGIN_ADMIN]} />
        <Item flex style={{ ...styleHeaderFix }}>
          <Switch>
            <Route
              exact
              path={routes.LOGIN}
              component={() => (
                <LoginContainer
                  authAdress="/auth/logIn"
                  localStorageConfig={{
                    refreshToken: localStorage.REFRESH_TOKEN,
                    accessToken: localStorage.ACCESS_TOKEN,
                    expireAt: localStorage.EXPIRE_AT,
                    adminToken: localStorage.ADMIN_TOKEN
                  }}
                  redirectTo={routes.DASHBOARD}
                  footerSize="50px"
                  headerSize="90px"
                />
              )}
            />
            <AuthenticatedRoute
              exact
              path={routes.DASHBOARD}
              component={DashboardContainer}
            />
            <AuthenticatedRoute
              exact
              path={routes.FUNNEL}
              component={FunnelModule}
            />
            <AuthenticatedRoute
              exact
              path={routes.RAMSES}
              component={RamsesModule}
            />
            <AuthenticatedRoute
              exact
              path={routes.AUDIENCE}
              component={AudienceModule}
            />
            <AuthenticatedRoute
              exact
              path={routes.ACTIVATION}
              component={ActivationModule}
            />
            <AuthenticatedRoute exact path={routes.PML} component={PMLModule} />
            <AuthenticatedRoute
              exact
              path={routes.CAMPAIGN}
              component={CampaignModule}
            />
            <AuthenticatedRoute
              exact
              path={routes.GLOBAL}
              component={GlobalModule}
            />
            <AuthenticatedRoute
              path={routes.DEFAULT}
              component={() => <Redirect to={routes.LOGIN} />}
            />
          </Switch>
        </Item>
        <FooterContainer />
        <EasterEgg />
      </Router>
    </LayoutApp>
  );
}

export default App;
