import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import Image from 'components/Image';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  nameError: false,
  responseRateError: false,
  visitRatesJ1Error: false,
  visitRatesJ2Error: false,
  visitRatesJ3Error: false,
  sommeError: false,
  ermesMediaKeyError: false
};
export const ModalForm = props => {
  const { onValidate, config, actionName, mediaLogos, channels } = props;
  const { themeColors } = useTheme();
  const [logoBase64, setLogoBase64] = React.useState(null);
  const [iconBase64, setIconBase64] = React.useState(null);
  const logo = mediaLogos?.find(elem => elem.key.toLowerCase() === config?.ermesMediaKey?.toLowerCase()) || null;
  const medias = [
    'Affichage',
    'Display',
    'Email',
    'Presse',
    'Radio',
    'Réseaux sociaux',
    'SEA',
    'SMS',
    'TV'
  ];
  const initialState = {
    name: config.name || null,
    responseRate: config.responseRate || 0.55,
    channel: config.channel || 0.5,
    visitRatesJ1: (config.visitRates && config.visitRates[0] * 100) || 80,
    visitRatesJ2: (config.visitRates && config.visitRates[1] * 100) || 15,
    visitRatesJ3: (config.visitRates && config.visitRates[2] * 100) || 5,
    ermesMediaKey: config.ermesMediaKey || '',
    media: config.mediaGroup || ''
  };
  const [
    {
      name,
      responseRate,
      channel,
      visitRatesJ1,
      visitRatesJ2,
      visitRatesJ3,
      ermesMediaKey,
      media
    },
    setState
  ] = React.useState(initialState);
  const [
    {
      nameError,
      // responseRateError,
      ermesMediaKeyError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (key, state) => {
    setError(prevState => ({ ...prevState, [`${key}Error`]: state }));
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = field => value => {
    const text = value.trim();
    toggleError(field, !text);
    setState(prevState => ({ ...prevState, [field]: text }));
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();

    if (!name) {
      toggleError('name', true);
      error = true;
    }
    // if (!responseRate) {
    //   toggleError('responseRate', true);
    //   error = true;
    // }
    // if (!visitRatesJ1) {
    //   toggleError('visitRatesJ1', true);
    //   error = true;
    // }
    // if (!visitRatesJ2) {
    //   toggleError('visitRatesJ2', true);
    //   error = true;
    // }
    // if (!visitRatesJ3) {
    //   toggleError('visitRatesJ3', true);
    //   error = true;
    // }
    if (!ermesMediaKey) {
      toggleError('ermesMediaKey', true);
      error = true;
    }

    if (
      parseInt(visitRatesJ1, 10) +
        parseInt(visitRatesJ2, 10) +
        parseInt(visitRatesJ3, 10) !==
      100
    ) {
      toggleError('visitRatesJ1', true);
      toggleError('visitRatesJ2', true);
      toggleError('visitRatesJ3', true);
      toggleError('somme', true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        responseRate,
        channel,
        ermesMediaKey,
        visitRates: [
          visitRatesJ1 / 100,
          visitRatesJ2 / 100,
          visitRatesJ3 / 100
        ],
        mediaGroup: media
      };
      if (logoBase64 || iconBase64) {
        Object.assign(datas, {
          logo: {
            id: logo?._id,
            name,
            logoBase64: logoBase64 || logo?.base64Image || '',
            iconBase64: iconBase64 || logo?.base64Icon || ''
          }
        });
      }
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const getFileBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    reader.onerror = () => {};
  };

  const imageUploadCallback = file =>
    // eslint-disable-next-line no-unused-vars
    new Promise((resolve, reject) =>
      getFileBase64(file, data => resolve({ data: { link: data } }))
    );

  const UploadMediaSection = () => {
    const [fileUploaded, setFileUploaded] = React.useState(null);
    const hiddenFileInput = React.useRef(null);

    const handleFile = async file => {
      const { data } = await imageUploadCallback(file);
      setLogoBase64(data?.link);
    };

    const handleChangeMedia = event => {
      const fileUp = event.target.files[0];
      setFileUploaded(fileUp);
      handleFile(fileUp);
    };

    const handleClick = () => {
      hiddenFileInput.current.click();
    };
    return (
      <FormSection title="Logo">
        <Container>
          <Row spacing={0}>
            <Item justify="flex-start">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={11} justify="flex-start">
                    {!logo && !logoBase64 && <Text>Image</Text>}
                    <Row spacing={0} justify="flex-start">
                      <Item xs={logo?.base64Image || logoBase64 ? 1 : 6}>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChangeMedia}
                          style={{ display: 'none' }}
                        />
                        {logo?.base64Image || logoBase64 ? (
                          <Image
                            src={logoBase64 || logo?.base64Image}
                            style={{ height: '40px' }}
                          />
                        ) : (
                          <TextField
                            small
                            label="sélectionner une image"
                            value={fileUploaded && fileUploaded.name}
                          />
                        )}
                      </Item>
                      <Item xs={2} justify="center">
                        <Button
                          variant="contained"
                          size="medium"
                          widthSize="small"
                          onClick={handleClick}
                        >
                          {logo?.base64Image || logoBase64
                            ? 'Modifier'
                            : 'Parcourir'}
                        </Button>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </Item>
          </Row>
        </Container>
      </FormSection>
    );
  };

  const UploadIconSection = () => {
    const [fileUploaded, setFileUploaded] = React.useState(null);
    const hiddenFileInput = React.useRef(null);

    const handleFile = async file => {
      const { data } = await imageUploadCallback(file);
      setIconBase64(data?.link);
    };

    const handleChangeIcon = event => {
      const fileUpd = event.target.files[0];
      setFileUploaded(fileUpd);
      handleFile(fileUpd);
    };

    const handleClick = () => {
      hiddenFileInput.current.click();
    };
    return (
      <FormSection title="Icône">
        <Container>
          <Row spacing={0}>
            <Item justify="flex-start">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={11} justify="flex-start">
                    {!logo && !iconBase64 && <Text>Image</Text>}
                    <Row spacing={0} justify="flex-start">
                      <Item xs={logo?.base64Icon || iconBase64 ? 1 : 6}>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChangeIcon}
                          style={{ display: 'none' }}
                        />
                        {logo?.base64Icon || iconBase64 ? (
                          <Image
                            src={iconBase64 || logo?.base64Icon}
                            style={{ height: '40px' }}
                          />
                        ) : (
                          <TextField
                            small
                            label="sélectionner une image"
                            value={fileUploaded && fileUploaded.name}
                          />
                        )}
                      </Item>
                      <Item xs={2} justify="center">
                        <Button
                          variant="contained"
                          size="medium"
                          widthSize="small"
                          onClick={handleClick}
                        >
                          {logo?.base64Icon || iconBase64
                            ? 'Modifier'
                            : 'Parcourir'}
                        </Button>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </Item>
          </Row>
        </Container>
      </FormSection>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Nom de la régie"
                            required
                            placeholder="Choisisez un nom pour votre régie"
                            value={name}
                            onChange={handleChange('name')}
                            error={nameError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Canal</Text>
                          <Select
                            small
                            // label="Choisisez un canal"
                            value={channel}
                            onChange={handleChange('channel')}
                            options={channels}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="ermesRegieKey"
                            required
                            placeholder="Choisisez une ermesRegieKey"
                            value={ermesMediaKey}
                            onChange={handleChange('ermesMediaKey')}
                            error={ermesMediaKeyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Média</Text>
                          <Select
                            small
                            // label="Choisisez un canal"
                            value={media}
                            onChange={handleChange('media')}
                            options={medias}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <UploadMediaSection />
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <UploadIconSection />
          </Item>
        </Row>
        {/* <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Taux de visite théorique">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Taux de visite théorique"
                            required
                            value={responseRate}
                            onChange={handleChange('responseRate')}
                            error={responseRateError}
                            unit="%"
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row> */}
        {/* <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Répartition des visites">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Répartition premier jour"
                            required
                            value={visitRatesJ1}
                            onChange={handleChange('visitRatesJ1')}
                            error={visitRatesJ1Error}
                            unit="%"
                            format="numberInt"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Répartition jour 2"
                            required
                            value={visitRatesJ2}
                            onChange={handleChange('visitRatesJ2')}
                            error={visitRatesJ2Error}
                            unit="%"
                            format="numberInt"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Répartition jour 3"
                            required
                            value={visitRatesJ3}
                            onChange={handleChange('visitRatesJ3')}
                            error={visitRatesJ3Error}
                            unit="%"
                            format="numberInt"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color="error" show={sommeError}>
                            La somme des répartitions doit être égale à 100%
                          </Text>
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row> */}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
