export default {
  DASHBOARD: '/dashboard',
  DEFAULT: '/',
  GLOBAL: '/global',
  LOGIN: '/login',
  FUNNEL: '/funnel',
  AUDIENCE: '/audience',
  ACTIVATION: '/activation',
  RAMSES: '/ramses',
  PML: '/pml',
  CAMPAIGN: '/campaign'
};
