/* eslint-disable camelcase */

const themeV2 = {
  black: '#000000',
  white: '#ffffff',
  primary: '#E2E2E2',
  btnGreyBackground: '#F8F8F8',
  greyBackground: '#5E5F68',
  btnDisabledDark: '#949494',
  dashedBorder: '#cecece',
  greenInfoBg: '#E1EFE9',
  smsBgMessage: '#E6E5EB',
  error: '#F35959',
  red: '#FF0000',
  yellow: '#FFD700',
  baseGrey: '#C8C5C5',
  selectSelectedBg: '#D9D9D9',
  inputColor: '#E2E2E2',
  dropZoneBorder: '#D8D8D8',
  tuileTxt: '#383B50',
  googlePreview: {
    url: '#202124',
    title: '#1A0DAB',
    desc: '#4C4E58'
  },
  imageBlackOverlay: 'rgba(0, 0, 0, 0.6)',
  avatar: '#2D5BFF',
  subtitle: '#808080',
  detail: '#F5F5F5',
  detailDark: '#8A8A8A',
  link: '#2D5BFF',
  linkDisabled: '#B6C4F3',
  primaryLighten: '#E8EEFE',
  livetitle: '#FF0089',
  subtitleGrey: '#9CA2A7',
  notValidated: '#FAA000',
  backgroundImage: '#E9EEF1',
  infoButton: '#F44336',
  activation: '#3EAC7B',
  activationDisabled: '#DBF2E8',
  darkBlue: '#00567B',
  funnelDarkBlue: '#00567B'
};

export default {
  dark: {
    primary_header: '#1e1e2f',
    primary: '#1e1e2f',
    secondary: '#383B50',
    tertiary: '#5A5A76',
    funnel: '#009fe3',
    funnel_dark: '#0086bf',
    funnel_baseline1: '#4C4E58',
    funnel_baseline2: '#f2f2f2',
    funnel_baseline3: '#009fe3',
    funnel_baseline4: '#999999',
    activation: '#3EAC7B',
    campaign: '#3EAC7B',
    pml: '#2D5BFF',
    black: '#000000',
    dark: '#000000',
    light: '#ffffff',
    lightSmooth: '#b3b3b3',
    red: '#FF0000',
    error: '#FF0000',
    success: '#28A745',
    danger: '#EB5757',
    data: '#e5007d',
    live: '#000000',
    content: '#2D5BFF',
    delete: '#F35959',
    disabled: 'rgba(0, 0, 0, 0.26)',
    data_result_label: '#e5007d',
    data_result_value: '#fff',
    data_action_text: '#fff',
    data_action_border: '#fff',
    insight: '#ef7d25',
    insight_secondary: '#383B50',
    insight_tertiary: '#5A5A76',
    insight_result_label: '#ef7d25',
    insight_result_value: '#fff',
    insight_action: '#fff',
    insight_action_text: '#fff',
    insight_action_border: '#fff',
    insight_top_border: '#fff',
    audience: '#ef7d25',
    audience_secondary: '#383B50',
    audience_tertiary: '#5A5A76',
    audience_result_label: '#ef7d25',
    audience_result_value: '#fff',
    audience_action: '#fff',
    audience_action_text: '#fff',
    audience_action_border: '#fff',
    audience_top_border: '#fff',
    data_button_border_bg: '#fff',
    shadow: 'transparent',
    baseline_funnel: '#009fe3',
    baseline_funnel_inversed: '#ffffff',
    keep_light: '#ffffff',
    keep_dark: '#000000',
    icon: '#EEEEEE',
    icon_audience: '#EEEEEE',
    icon_insight: '#EEEEEE',
    activation_inversed: '#3EAC7B',
    funnel_inversed: '#009fe3',
    dialog_primary: '#1e1e2f',
    dialog_secondary: '#383b50',
    funnel_anom_row_color: '#D9F1FB',
    funnel_light_grey: '#BDBDBD',
    greyLight: '#828282',
    grey_light_button: '#F5F5F5',
    graph_color_1: '#94DF5B',
    graph_color_2: '#FFAA45',
    graph_color_3: '#D782E9',
    funnel_audience_analysis_positive: '#3EAC7B',
    funnel_audience_analysis_negative: '#FF0000',
    funnel_audience_analysis_neutral: '#4C4E58',
    funnel_audience_analysis_gender: {
      light: '#85929E',
      dark: '#2E4053'
    },
    funnel_audience_analysis_age: {
      light: '#7FB3D5',
      dark: '#174A6C'
    },
    funnel_audience_analysis_csp: {
      light: '#73C6B6',
      dark: '#138D75'
    },
    funnel_audience_analysis_zone: {
      light: '#BB8FCE',
      medium: '#8E44AD',
      dark: '#5B2C6F'
    },
    backoffice: '#F2C94C',
    global: '#F2C94C',
    badgeActif: '#CFF5B7',
    badgeDomains: '#F5EFB7',
    badgeFunnel: '#B7E6F5',
    error_gestion_campagne: '#F5C2B7',
    btnBack: '#ffffff',
    btnBackLabel: '#000000',
    themeV2
  },
  light: {
    delete: '#F35959',

    primary_header: '#1e1e2f',
    primary: '#eef4f9',
    secondary: '#ffffff',
    tertiary: '#f2f2f2',
    funnel: '#009fe3',
    funnel_dark: '#0086bf',
    funnel_baseline1: '#4C4E58',
    funnel_baseline2: '#f2f2f2',
    funnel_baseline3: '#009fe3',
    funnel_baseline4: '#999999',
    activation: '#3EAC7B',
    campaign: '#3EAC7B',
    live: '#000000',
    content: '#2D5BFF',
    pml: '#2D5BFF',
    dark: '#ffffff',
    black: '#000000',
    light: '#4C4E58',
    disabled: 'rgba(0, 0, 0, 0.26)',
    lightSmooth: '#ADAFB8',
    red: '#FF0000',
    error: '#FF0000',
    error_gestion_campagne: '#F5C2B7',
    success: '#28A745',
    danger: '#EB5757',
    data: '#e5007d',
    data_result_label: '#000000',
    data_result_value: '#e5007d',
    data_action_text: '#e5007d',
    data_action_border: '#e5007d',
    insight: '#ef7d25',
    insight_secondary: '#e0e0e0',
    insight_tertiary: '#C8C5C5',
    insight_result_label: '#000000',
    insight_result_value: '#ef7d25',
    insight_action_text: '#ef7d25',
    insight_action_border: '#ef7d25',
    insight_top_border: 'transparent',
    audience: '#ef7d25',
    audience_secondary: '#e0e0e0',
    audience_tertiary: '#C8C5C5',
    audience_result_label: '#000000',
    audience_result_value: '#ef7d25',
    audience_action_text: '#ef7d25',
    audience_action_border: '#ef7d25',
    audience_top_border: 'transparent',
    ramses: '#FF9900',
    data_button_border_bg: 'transparent',
    shadow: 'lightgrey',
    baseline_funnel: '#ffffff',
    baseline_funnel_inversed: '#009fe3',
    keep_light: '#ffffff',
    keep_dark: '#4C4E58',
    icon: '#4C4E58',
    icon_insight: '#C8C5C5',
    icon_audience: '#C8C5C5',
    activation_inversed: '#3EAC7B',
    funnel_inversed: '#009fe3',
    dialog_primary: '#ffffff',
    dialog_secondary: '#eef4f9',
    funnel_anom_row_color: '#D9F1FB',
    funnel_light_grey: '#828282',
    greyLight: '#C8C5C5',
    grey_light_button: '#F5F5F5',
    graph_color_1: '#94DF5B',
    graph_color_2: '#FFAA45',
    graph_color_3: '#D782E9',
    funnel_audience_analysis_positive: '#3EAC7B',
    funnel_audience_analysis_negative: '#FF0000',
    funnel_audience_analysis_neutral: '#4C4E58',
    funnel_audience_analysis_gender: {
      light: '#85929E',
      dark: '#2E4053'
    },
    funnel_audience_analysis_age: {
      light: '#7FB3D5',
      dark: '#174A6C'
    },
    funnel_audience_analysis_csp: {
      light: '#73C6B6',
      dark: '#138D75'
    },
    funnel_audience_analysis_zone: {
      light: '#BB8FCE',
      medium: '#8E44AD',
      dark: '#5B2C6F'
    },
    backoffice: '#F2C94C',
    global: '#F2C94C',
    badgeActif: '#CFF5B7',
    badgeDomains: '#F5EFB7',
    badgeFunnel: '#B7E6F5',
    btnBack: '#000000',
    btnBackLabel: '#ffffff',
    themeV2
  }
};
