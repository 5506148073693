import React from 'react';
import { LinearProgress as LinearProgressComp } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color';

import { useTheme } from 'contexts/ThemeContext';

import Fade from 'components/Fade';

export const LinearProgress = props => {
  const {
    color,
    show,
    size,
    value,
    valueBuffer,
    variant,
    showValue,
    ...rest
  } = props;
  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];
  const sizeRules = (size === 'medium' && 10) || (size === 'large' && 25);

  const useStyles = makeStyles({
    linearProgress: {
      flex: 1,
      height: sizeRules,
      borderRadius: '30px',
      backgroundColor: Color(themeColors.tertiary)
        .darken(0.1)
        .rgb()
        .string(),
      '& .MuiLinearProgress-bar': {
        backgroundColor: colorRules,
        borderRadius: '30px',
        '&.MuiLinearProgress-bar2Buffer': {
          backgroundColor: Color(colorRules)
            .fade(0.5)
            .rgb()
            .string()
        }
      }
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <LinearProgressComp
        className={classes.linearProgress}
        value={value}
        valueBuffer={valueBuffer}
        variant={variant}
        {...rest}
      />
    </Fade>
  );
};

export default { LinearProgress };
