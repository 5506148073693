import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import NavigationProvider from './contexts/NavigationContext';
import App from './App';

function Ramses(props) {
  const { match, ...appProps } = props;

  return (
    <NavigationProvider>
      <App {...appProps} />
    </NavigationProvider>
  );
}

Ramses.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(Ramses);
