import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import IntervalTimer from 'react-interval-timer';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import moment from 'utils/moment';
import { PML } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

const ConfigContainer = () => {
  const { adminRights, user } = useUser();
  const { themeColors } = useTheme();
  const [original, setOriginal] = useState(null);
  const [config, setConfig] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [computing, setComputing] = useState(false);
  const [openModal, setModal] = useState(null);
  const [setupTask, setSetupTask] = useState(false);

  const getDatas = async () => {
    const tmpConfigList = await PML.getConfigs();
    const tmpConfig = tmpConfigList[0];
    let task = null;
    if (tmpConfig) {
      task = await PML.getLastSetupTask(tmpConfig?.id);
    }
    setConfig(tmpConfig);
    setOriginal(tmpConfig);
    setSetupTask(task);
    setLoaded(true);
  };

  const getStatus = async () => {
    if (config) {
      const task = await PML.getLastSetupTask(config.id);
      setSetupTask(task);
      setComputing(task?.step === 'inProgress');
    }
  };

  const closeModal = () => {
    setModal(null);
  };

  const onAdd = () => {
    if (adminRights?.pml?.config?.create) {
      setConfig({ domain: user.domain, googleSheetUrl: '' });
      setOriginal({ domain: user.domain, googleSheetUrl: '' });
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async () => {
    if (adminRights?.pml?.config?.update) {
      if (config.id) {
        await PML.updateConfig(config.id, config);
      } else {
        await PML.createConfig(config);
      }
      setLoaded(false);
    } else {
      setModal('unauthorized');
    }
  };

  const onCompute = async (line = '') => {
    if (adminRights?.pml?.config?.update) {
      setComputing(true);
      await PML.computeConfig(config.id, line);
      await getStatus();
    } else {
      setModal('unauthorized');
    }
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const getStatusTxt = status => {
    switch (status) {
      case 'inProgress':
        return (
          <Text component="span" fontWeight={400} color="pml">
            En cours
          </Text>
        );
      case 'error':
        return (
          <Text component="span" fontWeight={400} color="error">
            Erreur
          </Text>
        );
      case 'done':
        return (
          <Text component="span" fontWeight={400} color="activation">
            Terminé
          </Text>
        );
      default:
        return (
          <Text component="span" fontWeight={400} color="activation">
            {status}
          </Text>
        );
    }
  };

  const onStop = () => {
    PML.stopSetupTask(setupTask.id);
  };

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  const isInprogress = setupTask?.status === 'inProgress';

  return (
    <Container>
      <IntervalTimer
        timeout={3000}
        callback={getStatus}
        enabled={!!config}
        repeat
      />
      {!config && (
        <Row>
          <Item justify="center">
            <ButtonCreate
              onClick={onAdd}
              text="Ajouter une configuration"
              justify="center"
            />
          </Item>
          <Item>
            <Text fontWeight={400} fontSize="14px">
              Pas de configuration pour ce domaine.
            </Text>
          </Item>
        </Row>
      )}
      {!!config && (
        <Row>
          <Item>
            <Text fontWeight={700} fontSize="14px">
              Configuration plateforme multi-locale
            </Text>
          </Item>
          <Item>
            <Row spacing={4}>
              <Item xs={4}>
                <TextField title="Domaine" disabled value={config.domain} />
              </Item>
              <Item xs={4}>
                <TextField
                  title="URL du Google Sheet"
                  value={config?.googleSheetUrl}
                  onChange={googleSheetUrl => {
                    setConfig({ ...config, googleSheetUrl });
                  }}
                />
              </Item>
            </Row>
          </Item>
          <Item>
            {(!setupTask || !isInprogress) && (
              <Row>
                <Item flex style={{ padding: '10px' }}>
                  <Button
                    onClick={onUpdate}
                    variant="contained"
                    color={
                      original.googleSheetUrl === config.googleSheetUrl
                        ? themeColors.black
                        : undefined
                    }
                    disabled={
                      original.googleSheetUrl === config.googleSheetUrl ||
                      !config.googleSheetUrl
                    }
                    style={{ padding: '10px 16px' }}
                  >
                    <SaveIcon />
                    &nbsp; Sauvegarder
                  </Button>
                </Item>
                {!!config.id && (
                  <Item flex style={{ padding: '10px' }}>
                    <Button
                      variant="contained"
                      onClick={() => onCompute()}
                      loading={computing}
                      color={
                        original.googleSheetUrl !== config.googleSheetUrl
                          ? themeColors.black
                          : undefined
                      }
                      disabled={
                        original.googleSheetUrl !== config.googleSheetUrl ||
                        !config.googleSheetUrl
                      }
                      style={{ padding: '10px 16px' }}
                    >
                      <Container spacing={0}>
                        <Row spacing={0} alignItems="center">
                          <Item flex style={{ paddingRight: '10px' }}>
                            {computing ? (
                              <Loading
                                loading
                                width="24px"
                                color={themeColors.keep_light}
                              />
                            ) : (
                              <FlightTakeoffIcon />
                            )}
                          </Item>
                          <Item xs>
                            <Text
                              fontSize="inherit"
                              fontWeight="inherit"
                              color="inherit"
                            >
                              Importer la configuration
                            </Text>
                          </Item>
                        </Row>
                      </Container>
                    </Button>
                  </Item>
                )}
                {setupTask?.status === 'error' && setupTask.lastLine !== 0 && (
                  <Item flex style={{ padding: '10px' }}>
                    <Button
                      variant="contained"
                      onClick={() => onCompute(setupTask.lastLine)}
                      loading={computing}
                      color={
                        original.googleSheetUrl !== config.googleSheetUrl
                          ? themeColors.black
                          : undefined
                      }
                      disabled={
                        original.googleSheetUrl !== config.googleSheetUrl ||
                        !config.googleSheetUrl
                      }
                      style={{ padding: '10px 16px' }}
                    >
                      <Container spacing={0}>
                        <Row spacing={0} alignItems="center">
                          <Item flex style={{ paddingRight: '10px' }}>
                            {computing ? (
                              <Loading
                                loading
                                width="24px"
                                color={themeColors.keep_light}
                              />
                            ) : (
                              <ReplayIcon />
                            )}
                          </Item>
                          <Item xs>
                            <Text
                              fontSize="inherit"
                              fontWeight="inherit"
                              color="inherit"
                            >
                              Reprendre
                            </Text>
                          </Item>
                        </Row>
                      </Container>
                    </Button>
                  </Item>
                )}
              </Row>
            )}
            {isInprogress && (
              <Row>
                <Item flex style={{ padding: '10px' }}>
                  <Button
                    onClick={onStop}
                    variant="contained"
                    color={themeColors.black}
                    disabled={!isInprogress}
                    style={{ padding: '10px 16px' }}
                  >
                    <CancelIcon />
                    &nbsp; Arreter
                  </Button>
                </Item>
              </Row>
            )}
            {setupTask && (
              <Row justify="center">
                <Item>
                  <Text fontWeight={700}>
                    {setupTask.status === 'inProgress'
                      ? "Le fichier est en cours d'import"
                      : 'Historique de votre configuration :'}
                  </Text>
                </Item>
                <Item flex style={{ margin: '50px', maxWidth: '450px' }}>
                  <Row spacing={0}>
                    <Item justify="flex-start">
                      <Text fontWeight={700}>
                        Date d&lsquo;import :&nbsp;
                        <Text component="span" fontWeight={400}>
                          {moment(
                            isInprogress
                              ? setupTask.createdAt
                              : setupTask.updatedAt
                          ).format('DD/MM/YYYY HH:mm:ss')}
                        </Text>
                      </Text>
                    </Item>
                    {isInprogress && (
                      <Item justify="flex-start">
                        <Text fontWeight={700}>
                          En cours depuis :&nbsp;
                          <Text component="span" fontWeight={400}>
                            {`${moment(setupTask.updatedAt).diff(
                              moment(setupTask.createdAt),
                              'minutes'
                            )} minutes`}
                          </Text>
                        </Text>
                      </Item>
                    )}
                    <Item justify="flex-start">
                      <Text fontWeight={700}>
                        Status :&nbsp;
                        {getStatusTxt(setupTask.status)}
                      </Text>
                    </Item>
                    {setupTask.step && (
                      <Item justify="flex-start">
                        <Row spacing={0}>
                          <Item flex>
                            <Text fontWeight={700}>Dernière étape: &nbsp;</Text>
                          </Item>
                          <Item xs justify="flex-start">
                            <Text
                              component="span"
                              fontWeight={400}
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {setupTask.step}
                            </Text>
                          </Item>
                        </Row>
                      </Item>
                    )}
                    {setupTask.message && (
                      <Item justify="flex-start">
                        <Row spacing={0}>
                          <Item justify="flex-start">
                            <Text fontWeight={700}>
                              Message d&lsquo;erreur &nbsp;
                            </Text>
                          </Item>
                          <Item
                            justify="flex-start"
                            style={{
                              backgroundColor: themeColors.primary_header,
                              padding: '10px',
                              overflowWrap: 'anywhere'
                            }}
                          >
                            <Text
                              color="keep_light"
                              fontWeight={400}
                              fontSize="10px"
                            >
                              {setupTask.message}
                            </Text>
                          </Item>
                        </Row>
                      </Item>
                    )}
                  </Row>
                </Item>
              </Row>
            )}
          </Item>
        </Row>
      )}
      {openModal === 'unauthorized' && (
        <UnauthorizedModal
          open={openModal === 'unauthorized'}
          onClose={closeModal}
        />
      )}
    </Container>
  );
};

export default ConfigContainer;
