import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { useTheme } from 'contexts/ThemeContext';

import moment from 'utils/moment';
import uuid from 'utils/uuid';

import { Divider, Text } from 'components/DataDisplay';
import { Column, Container, Item, Row } from 'components/Layout';

import { Button } from '../Button';
import { Switch } from '../Switch';

import DayPickerRangeControllerWrapper from './DateRangePicker';

export const FunnelCalendar = props => {
  const {
    isPending,
    onChange,
    onClearDate,
    start,
    end,
    compareStart,
    compareEnd,
    disableCampare,
    inline,
    displayDate,
    displayClearDate,
    color
  } = props;
  const { panelColor, themeColors } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const initialStartDate =
    start !== null
      ? moment(start)
          .utc()
          .startOf('day')
      : null;
  const initialEndDate =
    end !== null
      ? moment(end)
          .utc()
          .endOf('day')
      : null;
  const initialCompareStartDate = compareStart
    ? moment(compareStart || undefined)
        .utc()
        .startOf('day')
    : null;
  const initialCompareEndDate = compareEnd
    ? moment(compareEnd || undefined)
        .utc()
        .endOf('day')
    : null;

  const startShort =
    initialStartDate !== null ? initialStartDate.format('L') : null;
  const endShort = initialEndDate !== null ? initialEndDate.format('L') : null;
  const [periodes, setPeriodes] = useState({
    global: {
      startDate: initialStartDate,
      endDate: initialEndDate,
      keyId: uuid()
    },
    compare: {
      startDate: initialCompareStartDate,
      endDate: initialCompareEndDate,
      keyId: uuid()
    }
  });

  const [compareMode, setCompareMode] = useState(false);

  const colorRules = themeColors[panelColor];

  const presets = [
    {
      text: 'Mois en cours',
      startDate: moment()
        .utc()
        .startOf('month'),
      endDate: moment()
        .utc()
        .endOf('month')
    },
    {
      text: 'Mois dernier',
      startDate: moment()
        .utc()
        .subtract(1, 'months')
        .startOf('month'),
      endDate: moment()
        .utc()
        .subtract(1, 'months')
        .endOf('month')
    },
    {
      text: 'Trimestre en cours',
      startDate: moment()
        .utc()
        .quarter(moment().quarter())
        .startOf('quarter'),
      endDate: moment().endOf('day')
    },
    {
      text: 'Trimestre Dernier',
      startDate: moment()
        .utc()
        .quarter(moment().quarter())
        .subtract(1, 'quarters')
        .startOf('quarter'),
      endDate: moment()
        .utc()
        .quarter(moment().quarter())
        .subtract(1, 'quarters')
        .endOf('quarter')
    },
    {
      text: 'Année en cours',
      startDate: moment()
        .utc()
        .startOf('year'),
      endDate: moment()
        .utc()
        .endOf('day')
    }
  ];

  const onDatesChange = (key, refresh = false) => ({
    startDate: newStartDate,
    endDate: newEndDate
  }) => {
    setPeriodes({
      ...periodes,
      ...{
        [key]: {
          startDate: newStartDate,
          endDate: newEndDate,
          keyId: refresh ? uuid() : periodes[key].keyId
        }
      }
    });
  };

  const renderCalendarInfo = (type = 'global') => () => {
    return (
      <Container alignItems="center">
        <Row spacing={0}>
          <Item xs={11}>
            <Container>
              <Row spacing={0}>
                {presets.map(
                  (
                    { text, startDate: newStartDate, endDate: newEndDate },
                    index
                  ) => {
                    const key = `funnelPeriodleftMenu${type}_${index}`;
                    return (
                      <Item key={key}>
                        <Button
                          size="small"
                          rounded
                          style={{
                            '&:hover': {
                              color: themeColors.keep_light,
                              backgroundColor: colorRules
                            }
                          }}
                          onClick={() => {
                            onDatesChange(
                              type,
                              true
                            )({
                              startDate: newStartDate,
                              endDate: newEndDate
                            });
                          }}
                        >
                          {text}
                        </Button>
                      </Item>
                    );
                  }
                )}
              </Row>
            </Container>
          </Item>
          <Item xs={1}>
            <Divider
              orientation="vertical"
              color="keep_dark"
              style={{ width: '2px', height: '100%' }}
            />
          </Item>
        </Row>
      </Container>
    );
  };

  const getPeriodName = () => {
    const preset = presets.find(
      el =>
        el.startDate.format('L') === startShort &&
        el.endDate.format('L') === endShort
    );

    return preset?.text || `Custom`;
  };

  const getPeriodDates = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return `Date invalid - Date invalid`;
    }
    const shortEnd = endDate.format('L');
    const shortStart = startDate.format('L');
    if (shortStart === shortEnd) {
      return `Le ${shortEnd}`;
    }
    return `${shortStart} - ${shortEnd}`;
  };

  const useStyles = makeStyles(() => ({
    paper: {
      boxShadow: `-3px 3px 8px ${themeColors.popoverBoxShadowColor}`,
      backgroundColor: themeColors.secondary,
      borderRadius: '10px'
    }
  }));
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = event => {
    // reset states
    setPeriodes({
      global: {
        ...periodes.global,
        startDate: initialStartDate,
        endDate: initialEndDate
      },
      compare: {
        ...periodes.compare,
        startDate: initialCompareStartDate,
        endDate: initialCompareEndDate
      }
    });

    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const friendLyName = getPeriodName();
  const periodDates = getPeriodDates({
    startDate: initialStartDate,
    endDate: initialEndDate
  });

  const formatPeriodDate = () => {
    return displayDate ? `${periodDates}` : 'Date';
  };
  return (
    <Container>
      <Column spacing={0}>
        <Item style={{ cursor: 'pointer' }}>
          <Row spacing={0} onClick={isPending ? null : handleOpen}>
            <Item justify="flex-start">
              <Row
                spacing={0}
                justify="space-between"
                alignItems="center"
                style={{
                  padding: '7px 10px',
                  border: 'solid 1px',
                  borderRadius: '5px',
                  marginBottom: '6px',
                  color: isPending ? themeColors.light : themeColors.activation
                }}
              >
                <Item justify="flex-start" flex>
                  <Text color={color || themeColors.funnelCalendarSelectTxt}>
                    {!inline ? friendLyName : formatPeriodDate()}
                  </Text>
                </Item>
                <Item flex>
                  <CalendarTodayIcon
                    style={{
                      fill: color || themeColors.funnelCalendarSelectTxt
                    }}
                  />
                </Item>
              </Row>
              {!inline && (
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text
                      bold
                      noWrap
                      color={color || themeColors.funnelCalendarSelectTxt}
                    >
                      {periodDates}
                    </Text>
                  </Item>
                </Row>
              )}
              {!disableCampare && compareStart && compareEnd && (
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text>Comparer</Text>
                  </Item>
                  <Item justify="flex-start">
                    <Text noWrap>
                      {getPeriodDates({
                        startDate: initialCompareStartDate,
                        endDate: initialCompareEndDate
                      })}
                    </Text>
                  </Item>
                </Row>
              )}
            </Item>
          </Row>
          <Popover
            id={id}
            classes={{
              paper: classes.paper
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
          >
            <Container style={{ maxWidth: '830px' }}>
              <Row alignItems="flex-start" spacing={0}>
                <Item justify="flex-start" xs={12}>
                  <DayPickerRangeControllerWrapper
                    datePickerKey={periodes.global.keyId}
                    renderCalendarInfo={renderCalendarInfo('global')}
                    anchorDirection="right"
                    minimumNights={0}
                    onDatesChange={onDatesChange('global')}
                    startDate={periodes.global.startDate}
                    endDate={periodes.global.endDate}
                  />
                </Item>
              </Row>
              {!disableCampare && (
                <Row spacing={0}>
                  <Item flex style={{ width: '195px' }} />
                  <Item xs justify="flex-start">
                    <Text style={{ padding: '0px 5px' }}>Compare</Text>
                    <Switch
                      onChange={() => setCompareMode(!compareMode)}
                      checked={compareMode}
                    />
                  </Item>
                </Row>
              )}
              {!disableCampare && compareMode && (
                <Row alignItems="flex-start" spacing={0}>
                  <Item justify="flex-start" xs={12}>
                    <DayPickerRangeControllerWrapper
                      datePickerKey={periodes.compare.keyId}
                      renderCalendarInfo={renderCalendarInfo('compare')}
                      anchorDirection="right"
                      minimumNights={0}
                      onDatesChange={onDatesChange('compare')}
                      startDate={periodes.compare.startDate}
                      endDate={periodes.compare.endDate}
                    />
                  </Item>
                </Row>
              )}
              <Row
                spacing={0}
                style={{
                  padding: '5px 0px'
                }}
              >
                <Item alignItems="center" justify="center" xs={3}>
                  <Button
                    variant="contained"
                    color={color}
                    onClick={() => {
                      const realEnd =
                        periodes.global.endDate || periodes.global.startDate;

                      const startCompare =
                        periodes.compare.startDate || periodes.compare.endDate;

                      const endCompare =
                        periodes.compare.endDate || periodes.compare.startDate;

                      if (realEnd) {
                        onChange({
                          startDate: periodes.global.startDate,
                          endDate: realEnd,
                          compareStartDate: compareMode ? startCompare : null,
                          compareEndDate: compareMode ? endCompare : null
                        });
                      }
                      handleClose();
                    }}
                  >
                    Valider
                  </Button>
                </Item>
                {displayClearDate && (
                  <Item alignItems="center" justify="center" xs={3}>
                    <Button
                      variant="contained"
                      color={themeColors.keep_dark}
                      onClick={() => {
                        onClearDate();
                        handleClose();
                      }}
                    >
                      Reset
                    </Button>
                  </Item>
                )}
              </Row>
            </Container>
          </Popover>
        </Item>
      </Column>
    </Container>
  );
};
FunnelCalendar.defaultProps = {
  start: null,
  end: null,
  compareStart: null,
  compareEnd: null,
  onChange: () => {},
  onClearDate: () => {},
  disableCampare: false,
  displayClearDate: true,
  inline: false,
  color: null
};
FunnelCalendar.propTypes = {
  onChange: PropTypes.func,
  onClearDate: PropTypes.func,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  compareStart: PropTypes.instanceOf(Date),
  compareEnd: PropTypes.instanceOf(Date),
  disableCampare: PropTypes.bool,
  displayClearDate: PropTypes.bool,
  inline: PropTypes.bool,
  color: PropTypes.string
};

export default FunnelCalendar;
