import React, { useEffect, useState } from 'react';
import IntervalTimer from 'react-interval-timer';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import moment from 'utils/moment';
import pluralize from 'utils/pluralize';
import { CONNEXION, RAMSES_PROPERTIES, RAMSES_REPORTS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { ModalDelete } from 'components/ModalDelete';
import ModalLogs from 'components/ModalLogs';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const PropertiesContainer = () => {
  const [config, setConfig] = useState({});
  const { searchFieldValue, setSearchFieldValue } = useApp();
  const [connexions, setConnexions] = useState([]);
  const [lastStatus, setLastStatus] = useState([]);
  const [propertiesList, setProperties] = useState([]);
  const [reportsList, setReports] = useState([]);
  const [updateLogs, setUpdateLogs] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedReport, setSelectedReport] = useState({});
  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelectedReport({});
  };

  const selectReport = id => {
    const report = reportsList.find(el => el._id === id);
    setSelectedReport(report);

    return report;
  };

  const getProperties = async () => {
    try {
      const result = await RAMSES_PROPERTIES.get();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatRapport = datas => {
    const reports = datas.filter(el => !!el);
    const nbReports = reports.length;

    if (nbReports === 0) {
      return <Text>Aucun</Text>;
    }

    let str = `${reports[0]}`;
    if (nbReports > 1) {
      str = `${reports[0]} & ${nbReports - 1}
             ${pluralize('autre', nbReports - 1)}`;
    }

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{reports.map(report => getToolTipText(report))}</Item>
          </Row>
        </Container>
      );
    };

    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="ramses">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const seachKey = (item, toTest) => {
    const val = toTest || searchFieldValue.toLowerCase();
    for (const key in item) {
      if (
        typeof item[key] === 'string' &&
        item[key].toLowerCase().indexOf(val) !== -1
      ) {
        return true;
      }
    }
    return false;
  };

  const getDatas = async () => {
    const properties = await getProperties();
    const reports = await RAMSES_REPORTS.get();
    const connectorConnexions = await CONNEXION.getConnexions().then(val =>
      val
        .filter(el => el.model === 'amazonSeller')
        .map(el => ({ value: el._id, label: el.name }))
    );
    setLoaded(true);

    const status = await RAMSES_REPORTS.getLastReportsUpdateStatus(
      reports.map(el => el._id)
    );
    const mapped = reports.map(item => {
      const newItem = {};
      newItem._id = item._id;
      newItem.name = item.name;
      newItem.properties = formatRapport(
        properties
          .filter(el => el.reports.filter(e => e?._id === item._id).length > 0)
          .map(e => e.name)
      );
      newItem.lastUpdate = moment(item.lastUpdate).format('DD/MM/YYYY');
      newItem.status = status.find(el => el.reportId === item._id);
      return newItem;
    });
    setLastStatus(status);
    setConnexions(connectorConnexions);
    setReports(reports);
    setProperties(properties);
    setRows(mapped);
    setAllRows(mapped);
  };

  const getLastUpdates = async () => {
    const status = await RAMSES_REPORTS.getLastReportsUpdateStatus(
      reportsList.map(el => el._id)
    );
    if (status) {
      setLastStatus(status);
    }
  };

  useEffect(() => {
    if (loaded) {
      const mapped = allRows.map(item => {
        const newItem = item;
        newItem.status = lastStatus.find(el => el.reportId === item._id);
        return newItem;
      });
      setRows(
        mapped.filter(el => {
          const findRow = rows.find(row => row._id === el._id);
          return findRow && seachKey(el);
        })
      );
      setAllRows(mapped);
    }
    // eslint-disable-next-line
  }, [lastStatus]);

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  useEffect(() => {
    return () => setSearchFieldValue('');
    // eslint-disable-next-line
  }, []);

  const openCreate = async () => {
    if (adminRights?.ramses?.reports?.create) {
      setConfig({ propertiesList, connexions });
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const deleteReportInProperties = async (properties, id) => {
    for await (const el of properties) {
      const reports = el.reports.map(e => e.id);
      if (reports.find(e => e === id)) {
        await RAMSES_PROPERTIES.update(el._id, {
          properties: {
            reports: reports.filter(e => e !== id)
          }
        });
      }
    }
  };

  const updateReportInProperties = async (selectedPropertie, id) => {
    const toDelete = propertiesList
      .filter(
        el => el.reports.filter(e => e._id === selectedReport._id).length > 0
      )
      .reduce((acc, el) => {
        if (!selectedPropertie.find(e => e._id === el.id)) {
          return [...acc, el];
        }
        return acc;
      }, []);

    await deleteReportInProperties(toDelete, id);
    for await (const el of selectedPropertie) {
      const propertie = propertiesList.find(e => el.id === e._id);
      const reports = propertie.reports.map(e => e.id);
      if (!reports.find(e => e === id)) {
        reports.push(id);
        await RAMSES_PROPERTIES.update(propertie._id, {
          properties: {
            reports
          }
        });
      }
    }
  };

  const onCreate = async report => {
    const rslt = await RAMSES_REPORTS.create({
      report
    });
    if (rslt?.data?._id) {
      await updateReportInProperties(report.selectedPropertie, rslt.data._id);
    }
    closeModal();
    setLoaded(false);
  };

  const openUpdate = id => {
    if (adminRights?.ramses?.reports?.update) {
      const report = selectReport(id);
      setConfig({
        propertiesList,
        connexions,
        propertie: {
          id,
          name: report.name,
          sellerId: report.sellerId,
          region: report.region,
          startDate: report.startDate,
          endDate: report.endDate,
          connexionId: report.connexionId,
          active: report.active,
          minimumStock: report.minimumStock,
          selectedPropertie: propertiesList.filter(
            el => el.reports.filter(e => e._id === id).length > 0
          )
        }
      });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async report => {
    await RAMSES_REPORTS.update(selectedReport._id, {
      report
    });
    await updateReportInProperties(
      report.selectedPropertie,
      selectedReport._id
    );
    closeModal();
    setLoaded(false);
  };

  // const openDelete = id => {
  //   if (adminRights?.ramses?.reports?.delete) {
  //     selectReport(id);
  //     setModal('modalDelete');
  //   } else {
  //     setModal('unauthorized');
  //   }
  // };
  const onDelete = async () => {
    await deleteReportInProperties(propertiesList, selectedReport._id);
    await RAMSES_REPORTS.deleteProperties(selectedReport._id);
    setLoaded(false);
    closeModal();
  };

  const onRefresh = async id => {
    if (adminRights?.ramses?.reports?.update) {
      await RAMSES_REPORTS.compute(id);
    } else {
      setModal('unauthorized');
    }
  };

  const openListLogs = async id => {
    selectReport(id);
    const logs = await RAMSES_REPORTS.getReportUpdateStatus(id, {
      short: true
    });
    setUpdateLogs(logs);
    setModal('modalLogs');
  };

  return (
    <Container>
      <IntervalTimer
        timeout={30000}
        callback={getLastUpdates}
        enabled={!openModal}
        repeat
      />
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={setRows}
            datas={allRows}
            titleHead="Recherche un rapport"
            placeholder="id, nom, rapport, ..."
            setSearchFieldValue={setSearchFieldValue}
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreate} text="Ajouter un rapport" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onCreate={onCreate}
              config={config}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'ID', id: '_id', sortKey: '_id' },
                { label: 'Nom', id: 'name', sortKey: 'name' },
                { label: 'Propriété', id: 'properties' },
                { label: 'Dernière MAJ', id: 'lastUpdate' }
              ]}
              onUpdate={openUpdate}
              // onDelete={openDelete}
              onRefresh={[
                { label: 'Mettre à jour les données', onClick: onRefresh }
              ]}
              onListLogs={openListLogs}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onCreate={onUpdate}
              config={config}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedReport.name}
              type="cette propriété"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
          {openModal === 'modalLogs' && (
            <ModalLogs
              name={selectedReport.name}
              title="Historique des mises à jour du report"
              datas={[
                {
                  label: 'Report',
                  values: updateLogs
                }
              ]}
              onClose={() => {
                setSelectedReport({});
                setUpdateLogs([]);
                setModal('');
              }}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default PropertiesContainer;
