import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';

import { Container, Item, Row } from '../Layout';

const CustomAccordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  padding: '0px',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export const Accordion = ({
  rows = [],
  underline,
  onExpandChange = null,
  expanded = false,
  backgroundColor = 'transparent'
}) => {
  const { themeColors } = useTheme();

  const AccordionSummary = styled(props => (
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(() => ({
    backgroundColor: 'transparent',
    // flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)'
    },
    '& .MuiAccordionSummary-content': {
      margin: '0px'
    }
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: '0px 0px 0px 45px',
    border: 'none'
  }));

  const [localExpanded, setLocalExpanded] = React.useState(
    rows.find(({ open }) => open)?.key || expanded
  );

  const handleChange = panel => {
    if (typeof onExpandChange === 'function') {
      onExpandChange(panel);
    } else {
      setLocalExpanded(localExpanded ? false : panel);
    }
  };

  useEffect(() => {
    setLocalExpanded(rows.find(({ open }) => open)?.key || expanded);
    // eslint-disable-next-line
  }, [rows, expanded]);

  return (
    <Container spacing={0}>
      {rows.map(row => {
        const isExpanded = localExpanded === row.key || localExpanded;
        return (
          <CustomAccordion
            style={{
              width: '100%',
              border: 'none',
              backgroundColor: `${backgroundColor}`
            }}
            key={`accordion_${row.key}`}
            expanded={isExpanded}
          >
            <AccordionSummary
              style={{
                borderBottom: underline
                  ? `1px solid ${themeColors.light}`
                  : 'none'
              }}
              expandIcon={
                <ExpandMoreIcon
                  style={{ fill: themeColors.funnel }}
                  onClick={() => {
                    handleChange(row.key);
                  }}
                />
              }
            >
              <Container spacing={0}>
                <Row spacing={0} justify="flex-start" alignItems="center">
                  <Item xs justify="flex-start">
                    {row.label}
                  </Item>
                  {row.instructions && (
                    <Item flex>
                      <Text
                        onClick={() => {
                          handleChange(row.key);
                        }}
                        link
                        color="funnel"
                        underline
                      >
                        {isExpanded
                          ? row.instructions.hide
                          : row.instructions.show}
                      </Text>
                    </Item>
                  )}
                </Row>
              </Container>
            </AccordionSummary>
            {isExpanded && row.items && (
              <AccordionDetails>
                <Container spacing={0}>
                  {row.items.map(el => (
                    <Row spacing={0} key={`details_${el.key}`}>
                      <Item justify="flex-start">{el}</Item>
                    </Row>
                  ))}
                </Container>
              </AccordionDetails>
            )}
          </CustomAccordion>
        );
      })}
    </Container>
  );
};

export default Accordion;
