import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { RAMSES_PROPERTIES, RAMSES_REPORTS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const PropertiesContainer = () => {
  const [config, setConfig] = useState({});
  const [propertiesList, setProperties] = useState([]);
  const [reportsList, setReports] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedPropertie, setSelectedPropertie] = useState({});
  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelectedPropertie({});
  };

  const selectPropertie = id => {
    const propertie = propertiesList.find(el => el._id === id);
    setSelectedPropertie(propertie);

    return propertie;
  };

  const getProperties = async () => {
    try {
      const result = await RAMSES_PROPERTIES.get();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatRapport = datas => {
    const reports = datas.filter(el => !!el);
    const nbReports = reports.length;

    if (nbReports === 0) {
      return <Text>Aucun</Text>;
    }

    let str = `${reports[0].name}`;
    if (nbReports > 1) {
      str = `${reports[0].name} & ${nbReports - 1}
             ${pluralize('autre', nbReports - 1)}`;
    }

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{reports.map(report => getToolTipText(report.name))}</Item>
          </Row>
        </Container>
      );
    };

    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="ramses">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const getDatas = async () => {
    const properties = await getProperties();
    const reports = await RAMSES_REPORTS.get();
    setLoaded(true);
    const mapped = properties.map(item => {
      const newItem = {};
      newItem._id = item._id;
      newItem.name = item.name;
      newItem.reports = formatRapport(item.reports);
      return newItem;
    });
    setReports(reports);
    setProperties(properties);
    setRows(mapped);
    setAllRows(mapped);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const openCreate = async () => {
    if (adminRights?.ramses?.properties?.create) {
      setConfig({ reportsList });
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async data => {
    await RAMSES_PROPERTIES.create({
      properties: {
        name: data.name,
        reports: data.selectedReports.map(e => e._id)
      }
    });
    closeModal();
    setLoaded(false);
  };

  const openUpdate = id => {
    if (adminRights?.ramses?.properties?.update) {
      const propertie = selectPropertie(id);
      setConfig({
        reportsList,
        propertie: {
          id,
          name: propertie.name,
          selectedReports: propertie.reports
        }
      });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async data => {
    await RAMSES_PROPERTIES.update(selectedPropertie._id, {
      properties: {
        name: data.name,
        reports: data.selectedReports.map(el => el._id)
      }
    });
    closeModal();
    setLoaded(false);
  };

  const openDelete = id => {
    if (adminRights?.ramses?.properties?.delete) {
      selectPropertie(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onDelete = async () => {
    await RAMSES_PROPERTIES.deleteProperties(selectedPropertie._id);
    setLoaded(false);
    closeModal();
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={setRows}
            datas={allRows}
            titleHead="Recherche une propriété"
            placeholder="id, nom, rapport, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreate} text="Ajouter une propriété" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onCreate={onCreate}
              config={config}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Nom', id: 'name', sortKey: 'name' },
                { label: 'Rapports', id: 'reports' }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdate}
              onDelete={openDelete}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onCreate={onUpdate}
              config={config}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedPropertie.name}
              type="cette propriété"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default PropertiesContainer;
