import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { AutocompleteTextField, IconButton } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { TableData } from 'components/TableData';

export const TableConnectorComponent = props => {
  const {
    isCampaignSetupPending,
    mediaLogos,
    formattedPlatforms,
    displayInput,
    handleChangeExternalId,
    allPlatformAccounts,
    allPlatformPages,
    campaign,
    activationShopGroups
  } = props;

  const { themeColors } = useTheme();

  const formatAccount = (setup, row) => {
    if (row.media === 'sms') {
      return (
        <Text
          fontSize="12px"
          fontWeight={400}
          style={{ textAlign: 'left' }}
          color={themeColors.themeV2.black}
        >
          {setup?.name
            ? `${setup.name} (${setup.connexionId})`
            : setup.connexionId}
        </Text>
      );
    }
    if (setup?.connexionId) {
      return (
        <Text
          fontSize="12px"
          fontWeight={400}
          style={{ textAlign: 'left' }}
          color={themeColors.themeV2.black}
        >
          {setup.connexionId}
        </Text>
      );
    }
    return (
      <Text
        fontSize="12px"
        fontWeight={400}
        style={{ textAlign: 'left' }}
        color={themeColors.themeV2.black}
      >
        {`${setup.name} (${setup.config?.externalId})`}
      </Text>
    );
  };

  const isObject = data => {
    return data !== null && typeof data === 'object';
  };

  const formatItem = (value, row, type) => {
    if (value === null) {
      return <Text fontSize="12px" fontWeight={700} />;
    }

    const validatedAllPlatformAccounts = allPlatformAccountsTest => {
      const returnValue = allPlatformAccountsTest.reduce(
        (acc, currentValue) => {
          const key = currentValue?.config?.connexionId
            ? currentValue.config.connexionId
            : currentValue.config?.config?.connexionId;
          if (key) {
            acc.push({
              key,
              label: `${currentValue.groupName} - ${currentValue.name}`,
              group: currentValue.groupName || 'ermesCSM'
            });
          }
          return acc;
        },
        []
      );
      return returnValue;
    };

    if (row.drawer) {
      const group = activationShopGroups.filter(el =>
        el.shops?.includes(campaign.activationShopId)
      );

      const allAccountNames = new Set([campaign.groupName]);
      group.forEach(el => {
        allAccountNames.add(el.name);
        allAccountNames.add(el.parent);
      });

      const mediaAccounts = allPlatformAccounts.filter(
        el =>
          allAccountNames.has(el.groupName) &&
          (el.model === value?.config?.model || el.model === value?.model)
      );

      const mediaAccountsSMS = allPlatformAccounts.filter(
        el =>
          (allAccountNames.has(el.groupName) &&
            (el.model === value?.config?.model || el.model === value?.model)) ||
          el.model === 'spotHit'
      );

      const mediaPages = allPlatformPages.filter(
        el => allAccountNames.has(el.groupName) && el.media === value?.media
      );

      if (row.media === 'sms') {
        return (
          <Container flex>
            <Item justify="flex-start">
              <Row style={{ flexFlow: 'row', margin: '0px' }}>
                <AutocompleteTextField
                  options={
                    type === 'account'
                      ? validatedAllPlatformAccounts(mediaAccountsSMS)
                      : mediaPages.map(el => ({
                          key: el.config.connexionId
                            ? el.config.connexionId
                            : el.config.config.connexionId,
                          label: `${el.groupName} - ${el.name}`,
                          group: el.groupName || 'ermesCSM'
                        }))
                  }
                  formatLabel={option => {
                    return option.label;
                  }}
                  defaultValue={{
                    key: value?.config?.connexionId,
                    label: `${value?.config?.connexionId || ''}`
                  }}
                  onChange={handleChangeExternalId(type, value.media)}
                />
              </Row>
            </Item>
          </Container>
        );
      }
      return (
        <Container flex>
          <Item justify="flex-start">
            <Row style={{ flexFlow: 'row', margin: '0px' }}>
              <AutocompleteTextField
                options={
                  type === 'account'
                    ? mediaAccounts.map(el => ({
                        key: el.config.externalId
                          ? el.config.externalId
                          : el.config.externalId.externalId,
                        label: `${el.groupName} - ${el.name}`,
                        group: el.groupName || 'ermesCSM'
                      }))
                    : mediaPages.map(el => ({
                        key: el.config.externalId
                          ? el.config.externalId
                          : el.config.externalId.externalId,
                        label: `${el.groupName} - ${el.name}`,
                        group: el.groupName || 'ermesCSM'
                      }))
                }
                formatLabel={option => {
                  return option.label;
                }}
                defaultValue={{
                  key:
                    value?.config?.externalId ||
                    value?.config?.config?.externalId,
                  label: `${value?.config?.name || ''}`,
                  group: value?.config?.groupName || 'ermesCSM'
                }}
                onChange={handleChangeExternalId(type, value.media)}
              />
            </Row>
          </Item>
        </Container>
      );
    }
    if (isObject(value?.config)) {
      if (value.media === 'sms') {
        return (
          <Container flex>
            <Item justify="flex-start">{formatAccount(value.config, row)}</Item>
          </Container>
        );
      }
      if (isObject(value?.config?.config)) {
        return (
          <Container flex>
            <Item justify="flex-start">{formatAccount(value.config, row)}</Item>
          </Container>
        );
      }
    }
    if (value?.config === 'ermes Account') {
      return (
        <Container flex>
          <Item justify="flex-start">
            <Text
              fontSize="12px"
              fontWeight={400}
              color={themeColors.themeV2.black}
            >
              Compte Ermes
            </Text>
          </Item>
        </Container>
      );
    }
    return (
      <Container flex>
        <Item justify="flex-start">
          <Text
            fontSize="12px"
            fontWeight={400}
            color={
              type === 'account' ? themeColors.red : themeColors.themeV2.black
            }
          >
            Pas de compte défini
          </Text>
        </Item>
      </Container>
    );
  };

  return (
    <Item flex style={{ margin: '20px 10px 0px 10px' }}>
      <TableData
        style={{ borderRadius: '0px' }}
        noPaginate
        noBorderRadius
        noBorderColor
        noBoxShadow
        headerJustify="flex-start"
        sizeOfcolumnAction={{ width: '0px' }}
        colorHeader={themeColors.themeV2.btnGreyBackground}
        headers={[
          {
            id: 'media',
            label: 'Media',
            format: value => {
              const mediaLogo =
                mediaLogos.find(
                  el => el.key.toLowerCase() === value.toLowerCase()
                )?.base64Image || '';
              if (mediaLogo) {
                return (
                  <Container flex>
                    <Item justify="flex-start">
                      <Image src={mediaLogo} alt={value} height="38px" />
                    </Item>
                  </Container>
                );
              }
              return (
                <Container flex>
                  <Item justify="flex-start">
                    <Text>{value}</Text>
                  </Item>
                </Container>
              );
            }
          },
          {
            id: 'account',
            label: 'Compte publicitaire',
            format: (value, row) => {
              return formatItem(value, row, 'account');
            }
          },
          {
            id: 'page',
            label: 'Page',
            format: (value, row) => {
              return formatItem(value, row, 'page');
            }
          },
          {
            id: '',
            label: '',
            format: (value, row) => {
              return (
                <Container>
                  <Row spacing={0}>
                    <Item justify="flex-end">
                      <IconButton
                        disabled={isCampaignSetupPending}
                        onClick={() => {
                          displayInput(row._id);
                        }}
                      >
                        {row.drawer ? (
                          <CheckIcon style={{ color: themeColors.light }} />
                        ) : (
                          <CreateIcon style={{ color: themeColors.light }} />
                        )}
                      </IconButton>
                    </Item>
                  </Row>
                </Container>
              );
            }
          }
        ]}
        rows={formattedPlatforms}
      />
    </Item>
  );
};

TableConnectorComponent.defaultProps = {
  isCampaignSetupPending: false,
  mediaLogos: [],
  formattedPlatforms: [],
  displayInput: () => {},
  handleChangeExternalId: () => {}
};

TableConnectorComponent.propTypes = {
  isCampaignSetupPending: PropTypes.bool,
  mediaLogos: PropTypes.arrayOf(),
  formattedPlatforms: PropTypes.arrayOf(),
  displayInput: PropTypes.func,
  handleChangeExternalId: PropTypes.func
};

export default TableConnectorComponent;
