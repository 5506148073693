import React from 'react';
import { withRouter } from 'react-router-dom';

import InProgressContainer from 'containers/InProgressContainer';
import UnauthorizedContainer from 'containers/UnauthorizedContainer';

import { Container, Item, Row } from 'components/Layout';
import { Column, Grid, Switch, Wrapper } from 'components/LayoutApp';
import Visibilities from 'components/Visibilities';

// MENU
import PropertiesContainer from './containers/menus/PropertiesContainer';
import ReportsContainer from './containers/menus/ReportsContainer';
import SidebarContainer from './containers/SidebarContainer';
import { useNavigation } from './contexts/NavigationContext';

const App = () => {
  const { menu, showMenu } = useNavigation();
  const getContainer = () => {
    const menuName = menu.name;
    if (!menu.enabled) {
      return <UnauthorizedContainer />;
    }

    switch (menuName) {
      case 'reports':
        return <ReportsContainer />;
      case 'properties':
        return <PropertiesContainer />;
      default:
        return <InProgressContainer text={menuName} />;
    }
  };

  return (
    <Wrapper>
      <Grid>
        <Column>
          <SidebarContainer />
        </Column>
        <Column verticalySpaced>
          <Visibilities>
            <Switch>
              <Container>
                <Row spacing={0}>
                  <Item
                    alignItems="flex-start"
                    show={showMenu}
                    style={{ paddingRight: '8px' }}
                  >
                    {getContainer()}
                  </Item>
                </Row>
              </Container>
            </Switch>
          </Visibilities>
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default withRouter(App);
